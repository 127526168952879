// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  display: flex;
}
.layout__body {
  width: 100%;
}
.layout__body--header {
  height: 65px;
  box-shadow: 0px 1px 10px 0px rgba(50, 50, 50, 0.2);
}
.layout__body--main {
  padding: 16px;
  background-color: #f1f1f1;
  min-height: 93vh;
}`, "",{"version":3,"sources":["webpack://./src/containers/Layout/styles.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;AADJ;AAGI;EACI,WAAA;AADR;AAGQ;EACI,YAAA;EACA,kDAAA;AADZ;AAIQ;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAFZ","sourcesContent":["@import '../../utils/styles/global.scss';\n\n.layout{\n    display: flex;\n\n    &__body{\n        width: 100%;\n\n        &--header{\n            height: 65px;\n            box-shadow: 0px 1px 10px 0px rgba(50, 50, 50, 0.2);\n        }\n\n        &--main{\n            padding: 16px;\n            background-color: #f1f1f1;\n            min-height: 93vh;\n        }\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
