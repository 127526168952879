// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-header {
  display: flex;
  align-items: center;
  background-color: #0099cc;
  color: #fff;
  margin-bottom: 0;
  padding: 6px 8px;
  font-size: 18px;
  justify-content: space-between;
}
.content-header p {
  margin: 0;
}
.content-header button {
  padding: 2px 5px;
  background-color: transparent;
  color: white;
}
.content-header button:hover {
  color: white !important;
  border: 1px solid white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ContentHeader/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,8BAAA;AACJ;AACI;EACI,SAAA;AACR;AAEI;EACI,gBAAA;EACA,6BAAA;EACA,YAAA;AAAR;AAEQ;EACI,uBAAA;EACA,kCAAA;AAAZ","sourcesContent":[".content-header{\n    display: flex;\n    align-items: center;\n    background-color: #0099cc;\n    color: #fff;\n    margin-bottom: 0;\n    padding: 6px 8px;\n    font-size: 18px;\n    justify-content: space-between;\n\n    p{\n        margin: 0;\n    }\n\n    button{\n        padding: 2px 5px;\n        background-color: transparent;\n        color: white;\n\n        &:hover{\n            color: white !important;\n            border: 1px solid white !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
