export const selfEmploymentContext = {
        advertisingate: '',
        autoExpenses: '',
        autoMiles: '',
        bankCharges: '',
        business: '',
        commisionFees: '',
        duesPublications: '',
        eqipmentRentalExpenses: '',
        // footage: '',
        home: '',
        insurance: '',
        interestExpense: '',
        leagalProfessionals: '',
        mealsEntertainmentPaid: '',
        officeExpenses: '',
        officeRentExpenses: '',
        others: '',
        postage: '',
        repairsExpenses: '',
        suppliesExpenses: '',
        taxPayer: '',
        taxes: '',
        telephone: '',
        toolsEquipment: '',
        travelExpenses: '',
        utilities: '',
        wages: '',
    }