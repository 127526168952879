// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-details {
  background-color: white !important;
  padding: 25px;
}
.user-details__collapses-menu__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-details__collapses-menu__header--title {
  font-weight: bold;
  font-size: 1.2rem;
}
.user-details__collapses-menu__header--link {
  font-weight: 500;
  color: grey;
  cursor: pointer;
}
.user-details__collapses-menu__header--multi-section-search {
  margin: 15px 0;
  padding: 10px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}
.user-details__collapses-menu__success {
  background-color: rgb(133, 216, 133);
}
.user-details__backstep-modal {
  width: 100%;
}
.user-details__backstep-footer {
  display: flex;
  justify-content: flex-end;
}
.user-details__backstep-footer div {
  display: flex;
}

.onlineoffline {
  margin-bottom: 20px;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/containers/Leads/Lead/styles.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,aAAA;AACJ;AAEQ;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AAAZ;AAEY;EACI,iBAAA;EACA,iBAAA;AAAhB;AAGY;EACI,gBAAA;EACA,WAAA;EACA,eAAA;AADhB;AAIY;EACI,cAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;AAFhB;AAMQ;EACI,oCAAA;AAJZ;AAQI;EACI,WAAA;AANR;AASI;EACI,aAAA;EACA,yBAAA;AAPR;AASQ;EACI,aAAA;AAPZ;;AAYA;EACI,mBAAA;EACA,qBAAA;AATJ","sourcesContent":[".user-details{\n    background-color: white !important;\n    padding: 25px;\n\n    &__collapses-menu{\n        &__header {\n            display: flex;\n            align-items: center;\n            justify-content: space-between;\n\n            &--title {\n                font-weight: bold;\n                font-size: 1.2rem;\n            }\n\n            &--link {\n                font-weight: 500;\n                color: grey;\n                cursor: pointer;\n            }\n\n            &--multi-section-search{\n                margin: 15px 0;\n                padding: 10px;\n                border: 1px solid #dee2e6;\n                border-radius: 5px;\n            }\n        }\n\n        &__success{\n            background-color: rgb(133, 216, 133);\n        }\n    }\n\n    &__backstep-modal{\n        width: 100%;\n    }\n\n    &__backstep-footer{\n        display: flex;\n        justify-content: flex-end;\n\n        div{\n            display: flex;\n        }\n    }\n}\n\n.onlineoffline {\n    margin-bottom: 20px;\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
