export const timeZoneOptions = [
    {
        title: 'PST',
        timeZone: 'America/Los_Angeles'
    },
    {
        title: 'MST',
        timeZone: 'America/Denver'
    },
    {
        title: 'CST',
        timeZone: 'America/Chicago'
    },
    {
        title: 'EST',
        timeZone: 'America/New_York'
    },
    {
        title: 'IST',
        timeZone: 'Asia/Kolkata'
    },
]

