// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-actions {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 576px) {
  .add-actions {
    align-items: start;
  }
}

.add-dependent-btn {
  height: auto;
  font-size: 20px;
  line-height: 15px;
  text-align: right;
}
.add-dependent-btn.green {
  color: #fff;
  background: #48a458;
}
.add-dependent-btn.green:hover {
  color: #fff !important;
}
.add-dependent-btn span {
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/containers/Leads/Lead/GeneralInformation/Dependent/styles.scss","webpack://./src/utils/styles/global.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,yBAAA;AADJ;ACDQ;EDAR;IAKQ,kBAAA;EAAN;AACF;;AAEA;EACI,YAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AACJ;AACI;EACI,WAAA;EACA,mBAAA;AACR;AACQ;EACI,sBAAA;AACZ;AAGI;EACI,sBAAA;AADR","sourcesContent":["@import '../../../../../utils/styles/global.scss';\n\n.add-actions{\n    display: flex;\n    justify-content: flex-end;\n\n    @include breakpoint(sm) {\n        align-items: start;\n    }\n}\n.add-dependent-btn {\n    height: auto;\n    font-size: 20px;\n    line-height: 15px;\n    text-align: right;\n\n    &.green {\n        color: #fff;\n        background: #48a458;\n\n        &:hover {\n            color: #fff !important;\n        }\n    }\n\n    span {\n        vertical-align: middle;\n    }\n}","@mixin breakpoint($point) {\n    @if $point == sm {\n        @media (max-width: 576px) {\n            @content;\n        }\n    }\n\n    @else if $point == md {\n        @media (max-width: 768px) {\n            @content;\n        }\n    }\n\n    @else if $point == lg {\n        @media (max-width: 992px) {\n            @content;\n        }\n    }\n\n    @else if $point == xl {\n        @media (max-width: 1200px) {\n            @content;\n        }\n    }\n\n    @else if $point == xxl {\n        @media (max-width: 1400px) {\n            @content;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
