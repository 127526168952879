import axiosInstance from '../utils/environment'

class DashboardServices{
    public async getDashboardDetails(URL: string){
        try {
            const response: any = await axiosInstance.get(URL);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    public async getDashboardDepartmentTeamWiseDetails(URL: string){
        try {
            const response: any = await axiosInstance.get(URL);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    public async getDashboardLeadwiseWiseteammembersDetails(id: string){
        try {
            const response: any = await axiosInstance.get('team-lead/search-team-leads?teamId='+ id);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
const DashboardService = new DashboardServices()
export default DashboardService