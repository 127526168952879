// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 35px;
}
.header-container__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header-container__right .ant-avatar {
  cursor: pointer;
}
.header-container__right--content__title {
  font-weight: 600;
  font-size: 1rem;
}

.cell_icon {
  padding: 0 !important;
  font-size: 1.5rem;
}

.header_icons {
  display: inline-block !important;
  margin: 0 10px;
  padding: 0 !important;
}

.ant-popover-inner {
  padding: 5px !important;
}

.profile-list p {
  margin: 10px 15px !important;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AACI;EACQ,aAAA;EACA,mBAAA;EACA,yBAAA;AACZ;AACY;EACI,eAAA;AAChB;AAGgB;EACI,gBAAA;EACA,eAAA;AADpB;;AAOA;EACI,qBAAA;EACA,iBAAA;AAJJ;;AAOA;EACI,gCAAA;EACA,cAAA;EACA,qBAAA;AAJJ;;AAOA;EACI,uBAAA;AAJJ;;AAOI;EACI,4BAAA;EACA,eAAA;AAJR","sourcesContent":[".header-container{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 12px 35px;\n\n    &__right {\n            display: flex;\n            align-items: center;\n            justify-content: flex-end;\n    \n            .ant-avatar {\n                cursor: pointer;\n            }\n\n            &--content{\n                &__title{\n                    font-weight: 600;\n                    font-size: 1rem;\n                }\n            }\n        }\n}\n\n.cell_icon {\n    padding: 0 !important;\n    font-size: 1.5rem;\n}\n\n.header_icons {\n    display: inline-block !important;\n    margin: 0 10px;\n    padding: 0 !important;\n}\n\n.ant-popover-inner{\n    padding: 5px !important;\n}\n.profile-list{\n    p{\n        margin: 10px 15px !important;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
