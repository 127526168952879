// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.others_container table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.others_container td,
.others_container th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}`, "",{"version":3,"sources":["webpack://./src/containers/Leads/Lead/OtherIncome/styles.scss"],"names":[],"mappings":"AACI;EACI,8BAAA;EACA,yBAAA;EACA,WAAA;AAAR;AAGI;;EAEI,yBAAA;EACA,gBAAA;EACA,YAAA;AADR","sourcesContent":[".others_container {\n    table {\n        font-family: arial, sans-serif;\n        border-collapse: collapse;\n        width: 100%;\n    }\n\n    td,\n    th {\n        border: 1px solid #dddddd;\n        text-align: left;\n        padding: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
