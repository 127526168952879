export const rentalExpensesContext = {
    address: "",
    advertising: "",
    associationDues: "",
    autoMiles: "",
    autoTravel: "",
    clearingMaintainance: "",
    commisionsPaid: "",
    datePlacedInService: "",
    // expenses: "",
    groundsGardening: "",
    insurance: "",
    interestExpense: "",
    managementFees: "",
    others: "",
    others2: "",
    others3: "",
    // ownership: "",
    pestControl: "",
    propertyCostBasics: "",
    rentReceived: "",
    repairMaintainance: "",
    supplies: "",
    taxPayer: "",
    taxes: "",
    utilities: "",
    fairRentalDays: "",
    personalUseDays: ""
}