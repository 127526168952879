import React, { useCallback,useState } from 'react'
import { Col, Row, Form, Button } from "antd";
import { ISearchFilter } from './models'
import GenerateElements from '../GenerateElements';
import './styles.scss'
import dayjs from 'dayjs';

const SearchFilter: React.FC<ISearchFilter> = ({ fields, onSubmit, clearSearch, showButtons, colVal }) => {
    const [disableBtn, setDisableBtn] = useState<boolean>(true)
    const [form] = Form.useForm();

    const generateStateValues = useCallback(() => {
      const emptyValues: any = {}
      fields.forEach((field: any) => {
          emptyValues[field.key] = ''
      })

      return emptyValues
    },[fields])

    const onSubmitSearch = (values: any) => {
      const copyValues = {...values}
      if(fields.some((field: any) => field.elementType === 'DATE_PICKER_DATE_RANGE') && copyValues.date && copyValues.date.length === 2){
        copyValues['startDate'] = dayjs(copyValues.date[0]?.$d).format('YYYY-MM-DD')
        copyValues['endDate'] = dayjs(copyValues.date[1]?.$d).format('YYYY-MM-DD')
        delete copyValues.date
      }
      if(fields.some((field: any) => field.elementType === 'DATE_PICKER_DATE_TIME') && copyValues.date){
        copyValues.date = new Date(copyValues.date?.$d).toISOString()
      }
      const filterEmptyKeys = Object.keys(copyValues)
      const query: any = {}
      filterEmptyKeys.forEach((key: string) => {
        if(copyValues[key] !== '' && copyValues[key] !== undefined){
          query[key] = copyValues[key]
        }
      })
      const queryString: string = new URLSearchParams(query).toString()
      onSubmit(queryString)
    }

    const onClearSearch = () => {
      const stateValues = generateStateValues()
      form.setFieldsValue(stateValues)
      setDisableBtn(true)
      clearSearch()
    }

    const onChangeFieldValues = () => {
      if(form.isFieldsTouched()){
        setDisableBtn(false)
      }
    }

  return (
    <div className='search-filter'>
      <Form
          form={form}
          autoComplete="off"
          layout='horizontal'
          onFinish={onSubmitSearch}
          onValuesChange={onChangeFieldValues}
      >
        <Row
            gutter={{
                xs: 0,
                sm: 10,
                xl: 15,
            }}
          >
              {
                  fields.map((formItem: any, i: number) => (
                    <Col className="gutter-row" xl={colVal ? colVal : 6} sm={12} xs={24} key={i}>
                        <GenerateElements elementData={formItem} index={i} key={i} />
                    </Col>
                  ))
              }
              {
                showButtons && (
                  <>
                    <Col className="gutter-row">
                      <Form.Item>
                        <Button type='primary' htmlType="submit" disabled={disableBtn}>Submit</Button>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row">
                      <Form.Item>
                        <Button type='default' onClick={onClearSearch} disabled={disableBtn}>Clear Search</Button>
                      </Form.Item>
                    </Col>
                  </>
                )
              }
              
        </Row>
      </Form>
    </div> 
  )
}

export default SearchFilter