// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container__body {
  padding: 20px;
}

.labelname strong {
  line-height: 38px;
  width: 160px;
  display: inline-block;
}

.comments-section {
  margin-top: 20px;
}

.comments-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comment-item {
  justify-content: flex-start;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
  width: 100%;
}

.comment-item.left {
  align-items: flex-start;
}

.comment-item.right {
  align-items: flex-end;
  background: #e0f7fa;
  text-align: right;
}

.comment-content {
  max-width: 100%;
}

.comments-form {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/containers/Support/Form/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AACA;EACE,iBAAA;EACA,YAAA;EACA,qBAAA;AAEF;;AAAE;EACE,gBAAA;AAGJ;;AAAE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAGJ;;AAAE;EACE,2BAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;AAGJ;;AAAE;EACE,uBAAA;AAGJ;;AAAE;EACE,qBAAA;EACA,mBAAA;EACA,iBAAA;AAGJ;;AAAE;EACE,eAAA;AAGJ;;AAAE;EACE,gBAAA;AAGJ","sourcesContent":[".form-container__body {\n  padding: 20px;\n}\n.labelname strong {\n  line-height: 38px;\n  width: 160px;\n  display: inline-block;\n}\n  .comments-section {\n    margin-top: 20px;\n  }\n  \n  .comments-list {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n  \n  .comment-item {\n    justify-content: flex-start;\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    background: #f9f9f9;\n    width: 100%;\n  }\n  \n  .comment-item.left {\n    align-items: flex-start;\n  }\n  \n  .comment-item.right {\n    align-items: flex-end;\n    background: #e0f7fa; // Optional: Different background color for \"currentUser\" comments\n    text-align: right;\n  }\n  \n  .comment-content {\n    max-width: 100%;\n  }\n  \n  .comments-form {\n    margin-top: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
