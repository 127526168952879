// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.departments-container td {
  padding: 10px 16px !important;
}
.departments-container .permission_success {
  color: #29c429;
}
.departments-container .permission_danger {
  color: #e93535;
}`, "",{"version":3,"sources":["webpack://./src/containers/Departments/styles.scss"],"names":[],"mappings":"AACI;EACI,6BAAA;AAAR;AAGI;EACI,cAAA;AADR;AAII;EACI,cAAA;AAFR","sourcesContent":[".departments-container{\n    td{\n        padding: 10px 16px !important;\n    }\n\n    .permission_success{\n        color: #29c429;\n    }\n\n    .permission_danger{\n        color: #e93535;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
