// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
  margin: 10px;
  height: 40px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite;
  border-radius: 5px;
}

.large {
  height: 40px !important;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Skeletons/styles.scss"],"names":[],"mappings":"AAAG;EACI,YAAA;EACA,YAAA;EACA,yEAAA;EACA,0BAAA;EACA,8BAAA;EACA,kBAAA;AACP;;AAEG;EACC,uBAAA;AACJ;;AAEG;EACI;IACI,2BAAA;EACT;EAEK;IACI,4BAAA;EAAT;AACF","sourcesContent":["   .skeleton {\n       margin: 10px;\n       height: 40px;\n       background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);\n       background-size: 200% 100%;\n       animation: loading 2s infinite;\n       border-radius: 5px;\n   }\n\n   .large{\n    height: 40px !important;\n   }\n\n   @keyframes loading {\n       0% {\n           background-position: 200% 0;\n       }\n\n       100% {\n           background-position: -200% 0;\n       }\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
