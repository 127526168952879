// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lead-history {
  overflow: hidden;
}
.lead-history__modal {
  min-width: 800px;
}
@media (max-width: 768px) {
  .lead-history__modal {
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

.reportdata p {
  margin: 0;
}

.sucess-message {
  font-size: 36;
  color: #04AA6D;
}`, "",{"version":3,"sources":["webpack://./src/components/History/styles.scss","webpack://./src/utils/styles/global.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;AADJ;AAGI;EACI,gBAAA;AADR;ACGQ;EDHJ;IAIQ,2BAAA;IAAA,sBAAA;EAAV;AACF;;AAKI;EACI,SAAA;AAFR;;AAMA;EACI,aAAA;EACA,cAAA;AAHJ","sourcesContent":["@import '../../utils/styles/global.scss';\n\n.lead-history{\n    overflow: hidden;\n    \n    &__modal{\n        min-width: 800px;\n\n        @include breakpoint(md) {\n            min-width: fit-content;\n        }\n    }\n}\n\n.reportdata{\n    p{\n        margin: 0;\n    }\n}\n\n.sucess-message {\n    font-size: 36;\n    color: #04AA6D;\n}","@mixin breakpoint($point) {\n    @if $point == sm {\n        @media (max-width: 576px) {\n            @content;\n        }\n    }\n\n    @else if $point == md {\n        @media (max-width: 768px) {\n            @content;\n        }\n    }\n\n    @else if $point == lg {\n        @media (max-width: 992px) {\n            @content;\n        }\n    }\n\n    @else if $point == xl {\n        @media (max-width: 1200px) {\n            @content;\n        }\n    }\n\n    @else if $point == xxl {\n        @media (max-width: 1400px) {\n            @content;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
