// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tax-config-view {
  overflow: hidden;
  min-width: 1000px;
}
@media (max-width: 768px) {
  .tax-config-view {
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}`, "",{"version":3,"sources":["webpack://./src/containers/Leads/TaxConfigModal/styles.scss","webpack://./src/utils/styles/global.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACJ,iBAAA;AADA;ACKQ;EDNR;IAKQ,2BAAA;IAAA,sBAAA;EAAN;AACF","sourcesContent":["@import '../../../utils/styles/global.scss';\n\n.tax-config-view {\n    overflow: hidden;\nmin-width: 1000px;\n\n    @include breakpoint(md) {\n        min-width: fit-content;\n    }\n}","@mixin breakpoint($point) {\n    @if $point == sm {\n        @media (max-width: 576px) {\n            @content;\n        }\n    }\n\n    @else if $point == md {\n        @media (max-width: 768px) {\n            @content;\n        }\n    }\n\n    @else if $point == lg {\n        @media (max-width: 992px) {\n            @content;\n        }\n    }\n\n    @else if $point == xl {\n        @media (max-width: 1200px) {\n            @content;\n        }\n    }\n\n    @else if $point == xxl {\n        @media (max-width: 1400px) {\n            @content;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
