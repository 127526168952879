// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales-data-report__sales-links {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
}
.sales-data-report__sales-links button {
  margin-right: 5px;
  background-color: #6366F1;
  color: white;
}
.sales-data-report__timezones {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
}
.sales-data-report__timezones--time {
  min-width: 160px;
  text-align: center;
  border: 1px solid grey;
  margin-right: 5px;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}`, "",{"version":3,"sources":["webpack://./src/containers/Sales/Sales-Report/styles.scss"],"names":[],"mappings":"AAEI;EACI,cAAA;EACA,aAAA;EACA,eAAA;AADR;AAGQ;EACI,iBAAA;EACA,yBAAA;EACA,YAAA;AADZ;AAKI;EACI,aAAA;EACA,cAAA;EACA,eAAA;AAHR;AAKQ;EACI,gBAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AAHZ;;AAQA;EACI,2BAAA,EAAA,eAAA;EACE,yBAAA,EAAA,WAAA,EACC,mBAAA,EACE,4BAAA,EACC,2BAAA;EACI,iBAAA,EAAA;iDAAA;AAJd","sourcesContent":[".sales-data-report{\n\n    &__sales-links{\n        margin: 10px 0;\n        display: flex;\n        flex-wrap: wrap;\n        \n        button{\n            margin-right: 5px;\n            background-color: #6366F1;\n            color: white;\n        }\n    }\n\n    &__timezones{\n        display: flex;\n        margin: 10px 0;\n        flex-wrap: wrap;\n        \n        &--time{\n            min-width: 160px;\n            text-align: center;\n            border: 1px solid grey;\n            margin-right: 5px;\n            padding: 5px;\n            border-radius: 5px;\n            font-weight: 600;\n        }\n    }\n}\n\n.noselect {\n    -webkit-touch-callout: none; /* iOS Safari */\n      -webkit-user-select: none; /* Safari */\n       -khtml-user-select: none; /* Konqueror HTML */\n         -moz-user-select: none; /* Old versions of Firefox */\n          -ms-user-select: none; /* Internet Explorer/Edge */\n              user-select: none; /* Non-prefixed version, currently\n                                    supported by Chrome, Edge, Opera and Firefox */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
