// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unauthorized_container {
  display: grid;
  place-items: center;
}
.unauthorized_container--image img {
  object-fit: contain;
  height: 500px;
  width: 100%;
}
.unauthorized_container button {
  background-color: #ff4f5a !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UnAuthorized/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AAEQ;EACI,mBAAA;EACA,aAAA;EACA,WAAA;AAAZ;AAII;EACI,oCAAA;AAFR","sourcesContent":[".unauthorized_container{\n    display: grid;\n    place-items: center;\n\n    &--image{\n        img{\n            object-fit: contain;\n            height: 500px;\n            width: 100%;\n        }\n    }\n\n    button {\n        background-color: #ff4f5a !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
