export const options = [
    {
        label: 'Paid  Data / Sr Team',
        value: '1'
    },
    {
        label: 'UnPaid  Data / Jr Team',
        value: '2'
    },
    {
        label: 'All Members',
        value: '0'
    },
]