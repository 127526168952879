// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onlineoffline {
  margin-bottom: 20px;
  display: inline-block;
  width: 24%;
}

.document-section {
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #d9d9d9;
  margin: 10px;
}

.dbutton {
  color: #fff;
  background: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/containers/Leads/SubmissionSection/styles.scss"],"names":[],"mappings":"AAEA;EACI,mBAAA;EACA,qBAAA;EACA,UAAA;AADJ;;AAIA;EACI,aAAA;EACA,yBAAA;EACA,yBAAA;EACA,YAAA;AADJ;;AAKA;EACI,WAAA;EACA,mBAAA;EACA,0CAAA;EACA,YAAA;AAFJ","sourcesContent":["\n\n.onlineoffline {\n    margin-bottom: 20px;\n    display: inline-block;\n    width: 24%;\n}\n\n.document-section {\n    padding: 20px;\n    background-color: #f8f9fa;\n    border: 1px solid #d9d9d9;\n    margin: 10px;\n\n}\n\n.dbutton {\n    color: #fff;\n    background: #1677ff;\n    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);\n    float: right;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
