// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-filter {
  box-sizing: border-box;
  padding: 20px 0px 10px 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchFilter/styles.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,0BAAA;AACJ","sourcesContent":[".search-filter{\n    box-sizing: border-box;\n    padding: 20px 0px 10px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
