// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales-data-report__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sales-data-report__header p {
  font-weight: bold;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}`, "",{"version":3,"sources":["webpack://./src/containers/Sales/styles.scss"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAAR;AACQ;EACI,iBAAA;AACZ;;AAIA;EACI,2BAAA,EAAA,eAAA;EACE,yBAAA,EAAA,WAAA,EACC,mBAAA,EACE,4BAAA,EACC,2BAAA;EACI,iBAAA,EAAA;iDAAA;AAAd","sourcesContent":[".sales-data-report{\n    &__header{\n        width: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        p{\n            font-weight: bold;\n        }\n    }\n}\n\n.noselect {\n    -webkit-touch-callout: none; /* iOS Safari */\n      -webkit-user-select: none; /* Safari */\n       -khtml-user-select: none; /* Konqueror HTML */\n         -moz-user-select: none; /* Old versions of Firefox */\n          -ms-user-select: none; /* Internet Explorer/Edge */\n              user-select: none; /* Non-prefixed version, currently\n                                    supported by Chrome, Edge, Opera and Firefox */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
