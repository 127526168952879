import { Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import LeadService from '../../../../services/Lead.services';
import { initGetLead } from '../../../../store/actions/creators';
import { setError } from '../../../../store/reducers';
import '../styles.scss'

const Online = ({ leadData, selectedOption, fetchReviews }: any) => {
    const [showSecondOnlineSubmitFile, setShowSecondOnlineSubmitFile] = useState(false);
    const [secondFinalTaxFile, setSecondFinalTaxFile] = useState<any>({})
    const [selectedFiles, setSelectedFiles] = useState<any>({});
    const [selectedMultipleFiles, setSelectedMultipleFiles] = useState<any[]>([])
    const [offlineFiles, setOfflineFiles] = useState<any>([])
    const dispatch = useDispatch()
    const { onlineOfflineSubmission, onlineFinalSubmission } = LeadService
    
    const handleOnlineFileChange = (e: any) => {
        const { name, files } = e.target;
        setSelectedFiles((prevState: any) => ({
            ...prevState,
            [name]: files[0] // Assuming you're selecting one file at a time
        }));
    };

    const finalsubmitOnline = async ()  => {
        try {
            if(leadData === null){
                return;
            }
            const formData = new FormData();
            formData.append('leadType', selectedOption);
            formData.append('files', secondFinalTaxFile)
            const response = await onlineFinalSubmission(leadData?.lead_id, formData)
            if(response){
                dispatch(setError({ status: true, type: 'success', message: response?.message }))
                fetchReviews()
                dispatch(initGetLead(leadData?.lead_id))
                if(selectedOption === '1'){
                    setShowSecondOnlineSubmitFile(true)
                }
            }
        } catch (err: any) {
            dispatch(setError({ status: true, type: 'error', message: err }))
        }
    };

  const submitOnline = async ()  => {
    try {
        if(leadData === null){
            return;
        }
        const formData = new FormData();
        for (const key in selectedFiles) {
            if (selectedFiles[key]) {
                formData.append('files', selectedFiles[key]);
            }
        }
        formData.append('leadType', selectedOption);
        if(selectedOption === '2'){
        if(selectedMultipleFiles.length > 0){
                selectedMultipleFiles.forEach((file) => {
                    formData.append('others', file)
                })
            }
        }
        const response = await onlineOfflineSubmission(leadData?.lead_id, formData)
        if(response){
            dispatch(setError({ status: true, type: 'success', message: response?.message }))
            fetchReviews()
            dispatch(initGetLead(leadData?.lead_id))
            if(selectedOption === '1'){
                setShowSecondOnlineSubmitFile(true)
            }
        }
    } catch (err: any) {
        dispatch(setError({ status: true, type: 'error', message: err }))
    }
  };

  useEffect(() => {
    if(leadData && leadData?.online_offline_attachments){
        const files = leadData?.online_offline_attachments;
        const fileArray = files.split(',');
        setOfflineFiles(fileArray)
    }
  }, [leadData])

  return (
    <>
        {selectedOption === '1' && (

                <div className="document-section">
                   
                  {
                    showSecondOnlineSubmitFile || (leadData && leadData?.online_signature === '1') ? (
                      <>
                      <h3>Upload Final Tax Submisstion </h3>
                        <div className="onlineoffline"> Upload <strong>Final</strong> Tax file <input type="file" onChange={(e: any) => setSecondFinalTaxFile(e.target.files[0])} /> </div>
                        <div className="onlineoffline">
                             <Button className='dbutton' style={{margin: '5px 0'}} onClick={finalsubmitOnline}>Submit</Button>
                        </div>
                      </>
                    ) : (
                        
                        leadData?.lead_status !== "7" && <>
                        <h3>Online Tax Submisstion </h3>
                        <div className="onlineoffline"> Upload Tax file <input type="file" onChange={handleOnlineFileChange} /> </div>
                        <div className="onlineoffline">
                           <Button className='dbutton'
                                style={{margin: '5px 0'}}
                                onClick={submitOnline}
                            >Submit</Button>
                        </div>
                      </>
                    )
                  }
                  <>
                        <h6 className="mt-3" style={{textAlign:"right"}}>Online Tax file: &nbsp;   
                            {
                                offlineFiles.length > 0 ? (
                                    <>
                                        {
                                            offlineFiles.map((file: any) => (
                                                <span key={file}>
                                                    <a href={file} target="_blank" rel="noopener noreferrer">Tax file</a> <br></br>
                                                </span>
                                            ))
                                        }
                                    </>
                                ) : (
                                    <span>Empty</span>
                                )
                            }
                            </h6>
                            {
                                leadData?.online_final_attachments && (
                                <h6 className="mt-3" style={{textAlign:"left"}}>Online Final Tax file: &nbsp;   
                        
                                <span>
                                    <a href={leadData?.online_final_attachments} target="_blank" rel="noopener noreferrer">Final Tax file</a> <br></br>
                                </span>
                            </h6>
                                )
                            }
                    </>
                </div>
                
            )}
    </>
  )
}

export default Online