export const statusOptions = [
    {
        label: 'Process',
        value: '1'
    },
    {
        label: 'Preparation',
        value: '2'
    },
    {
        label: 'Review',
        value: '3'
    },
    {
        label: 'Finance',
        value: '4'
    },
    {
        label: 'Submission',
        value: '6'
    },
    {
        label: 'Completed',
        value: '7'
    }
]

export const departmentOptions = [
    {
        label: 'Process',
        value: '2'
    },
    {
        label: 'Preparation',
        value: '3'
    },
    {
        label: 'Review',
        value: '4'
    },
    {
        label: 'Finance',
        value: '5'
    },
    {
        label: 'Submission',
        value: '6'
    }
]
export const sourceOptions = [
    {
        label: 'Web',
        value: 'Web'
    },
    {
        label: 'Existing',
        value: 'Existing'
    },
    {
        label: 'New',
        value: 'New'
    },
    {
        label: 'Referal',
        value: 'Referal'
    },
]