// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container__body {
  padding: 35px 25px;
  background-color: white;
  border: 1px solid rgb(192, 190, 190);
}
.form-container__form-body {
  margin: 0 auto !important;
  max-width: 600px !important;
}
.form-container__form-body button:nth-child(1) {
  margin-right: 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/utils/styles/formStyles.scss"],"names":[],"mappings":"AAEI;EACI,kBAAA;EACA,uBAAA;EACA,oCAAA;AADR;AAII;EACI,yBAAA;EACA,2BAAA;AAFR;AAIQ;EACI,4BAAA;AAFZ","sourcesContent":[".form-container {\n\n    &__body {\n        padding: 35px 25px;\n        background-color: white;\n        border: 1px solid rgb(192, 190, 190);\n    }\n\n    &__form-body {\n        margin: 0 auto !important;\n        max-width: 600px !important;\n\n        button:nth-child(1) {\n            margin-right: 8px !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
