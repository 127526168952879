// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dependent-details, .showSpouse-details {
  margin-top: 20px;
}
.dependent-details__title, .showSpouse-details__title {
  font-size: 1.2rem;
  font-weight: bold;
}

.add-dependent-btn {
  margin: 10px 0 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/containers/Leads/Lead/GeneralInformation/styles.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EACI,iBAAA;EACA,iBAAA;AACR;;AAEA;EACI,qBAAA;AACJ","sourcesContent":[".dependent-details, .showSpouse-details{\n    margin-top: 20px;\n\n    &__title{\n        font-size: 1.2rem;\n        font-weight: bold;\n    }\n}\n.add-dependent-btn{\n    margin: 10px 0 20px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
